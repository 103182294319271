import type { RouterScrollBehavior } from 'vue-router';

const scrollBehavior: RouterScrollBehavior = (to, from, savedPosition) => {
  return new Promise((resolve) => {
    const nuxtApp = useNuxtApp();

    if (to.hash) {
      setTimeout(
        () => {
          resolve({
            el: to.hash,
            top: 60,
            behavior: 'smooth'
          });
        },
        !from || to.path !== from.path ? 500 : 1
      );
    } else if (savedPosition) {
      nuxtApp.hooks.hook('page:finish', async () => {
        await nextTick();
        setTimeout(() => {
          resolve({
            ...savedPosition,
            behavior: 'smooth'
          });
        }, 500);
      });
    } else {
      nuxtApp.hooks.hook('page:finish', async () => {
        await nextTick();
        setTimeout(() => {
          resolve({
            top: 0
          });
        }, 230);
      });
    }
  });
};

export default {
  scrollBehavior
};
