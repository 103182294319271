import { default as indexoxy6LOyVWQMeta } from "/opt/burinka/pages/[...uri]/index.vue?macro=true";
import { default as indexTUzAjm28unMeta } from "/opt/burinka/pages/aktuality-archiv/index.vue?macro=true";
import { default as _91slug_937DpK94xGb5Meta } from "/opt/burinka/pages/aktuality/[slug].vue?macro=true";
import { default as indexZUKs9wuBa2Meta } from "/opt/burinka/pages/aktuality/index.vue?macro=true";
import { default as _91slug_93l3uTJsSvqyMeta } from "/opt/burinka/pages/caste-dotazy/[slug].vue?macro=true";
import { default as indexTOJsVxc5xBMeta } from "/opt/burinka/pages/caste-dotazy/index.vue?macro=true";
import { default as _91_46_46_46slug_93BXpu36PXx7Meta } from "/opt/burinka/pages/dokumenty-ke-stazeni/[...slug].vue?macro=true";
import { default as index06DysLDIPbMeta } from "/opt/burinka/pages/dokumenty-ke-stazeni/index.vue?macro=true";
import { default as dokumenty_45ke_45stazenisOic8DWDQ7Meta } from "/opt/burinka/pages/dokumenty-ke-stazeni.vue?macro=true";
import { default as index0ZwhCR1k0XMeta } from "/opt/burinka/pages/index.vue?macro=true";
import { default as _91slug_93Qk5My755wgMeta } from "/opt/burinka/pages/kategorie-castych-dotazu/[slug].vue?macro=true";
import { default as indexwjvByEVt5wMeta } from "/opt/burinka/pages/kategorie-castych-dotazu/index.vue?macro=true";
import { default as kategorie_45castych_45dotazuFRLkiaWffeMeta } from "/opt/burinka/pages/kategorie-castych-dotazu.vue?macro=true";
import { default as dotaznikggW6pfJUKpMeta } from "/opt/burinka/pages/rekonstrukce/domov-pro-budoucnost/dotaznik.vue?macro=true";
import { default as _91slug_93SHuGnFga6NMeta } from "/opt/burinka/pages/slovnik-pojmu/[slug].vue?macro=true";
import { default as indexSpCe75sAQYMeta } from "/opt/burinka/pages/slovnik-pojmu/index.vue?macro=true";
import { default as slovnik_45pojmuabByRbjXFuMeta } from "/opt/burinka/pages/slovnik-pojmu.vue?macro=true";
import { default as indexPnnjNhzKYsMeta } from "/opt/burinka/pages/tiskove-zpravy/index.vue?macro=true";
import { default as indexokz4o3QXYxMeta } from "/opt/burinka/pages/vyhledavani/index.vue?macro=true";
import { default as indexU9qKqWDOY3Meta } from "/opt/burinka/pages/vyhody-od-burinky/index.vue?macro=true";
export default [
  {
    name: indexoxy6LOyVWQMeta?.name ?? "uri",
    path: indexoxy6LOyVWQMeta?.path ?? "/:uri(.*)*",
    meta: indexoxy6LOyVWQMeta || {},
    alias: indexoxy6LOyVWQMeta?.alias || [],
    redirect: indexoxy6LOyVWQMeta?.redirect,
    component: () => import("/opt/burinka/pages/[...uri]/index.vue").then(m => m.default || m)
  },
  {
    name: indexTUzAjm28unMeta?.name ?? "aktuality-archiv",
    path: indexTUzAjm28unMeta?.path ?? "/aktuality-archiv",
    meta: indexTUzAjm28unMeta || {},
    alias: indexTUzAjm28unMeta?.alias || [],
    redirect: indexTUzAjm28unMeta?.redirect,
    component: () => import("/opt/burinka/pages/aktuality-archiv/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_937DpK94xGb5Meta?.name ?? "aktuality-slug",
    path: _91slug_937DpK94xGb5Meta?.path ?? "/aktuality/:slug()",
    meta: _91slug_937DpK94xGb5Meta || {},
    alias: _91slug_937DpK94xGb5Meta?.alias || [],
    redirect: _91slug_937DpK94xGb5Meta?.redirect,
    component: () => import("/opt/burinka/pages/aktuality/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexZUKs9wuBa2Meta?.name ?? "aktuality",
    path: indexZUKs9wuBa2Meta?.path ?? "/aktuality",
    meta: indexZUKs9wuBa2Meta || {},
    alias: indexZUKs9wuBa2Meta?.alias || [],
    redirect: indexZUKs9wuBa2Meta?.redirect,
    component: () => import("/opt/burinka/pages/aktuality/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93l3uTJsSvqyMeta?.name ?? "caste-dotazy-slug",
    path: _91slug_93l3uTJsSvqyMeta?.path ?? "/caste-dotazy/:slug()",
    meta: _91slug_93l3uTJsSvqyMeta || {},
    alias: _91slug_93l3uTJsSvqyMeta?.alias || [],
    redirect: _91slug_93l3uTJsSvqyMeta?.redirect,
    component: () => import("/opt/burinka/pages/caste-dotazy/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexTOJsVxc5xBMeta?.name ?? "caste-dotazy",
    path: indexTOJsVxc5xBMeta?.path ?? "/caste-dotazy",
    meta: indexTOJsVxc5xBMeta || {},
    alias: indexTOJsVxc5xBMeta?.alias || [],
    redirect: indexTOJsVxc5xBMeta?.redirect,
    component: () => import("/opt/burinka/pages/caste-dotazy/index.vue").then(m => m.default || m)
  },
  {
    name: dokumenty_45ke_45stazenisOic8DWDQ7Meta?.name ?? undefined,
    path: dokumenty_45ke_45stazenisOic8DWDQ7Meta?.path ?? "/dokumenty-ke-stazeni",
    meta: dokumenty_45ke_45stazenisOic8DWDQ7Meta || {},
    alias: dokumenty_45ke_45stazenisOic8DWDQ7Meta?.alias || [],
    redirect: dokumenty_45ke_45stazenisOic8DWDQ7Meta?.redirect,
    component: () => import("/opt/burinka/pages/dokumenty-ke-stazeni.vue").then(m => m.default || m),
    children: [
  {
    name: _91_46_46_46slug_93BXpu36PXx7Meta?.name ?? "dokumenty-ke-stazeni-slug",
    path: _91_46_46_46slug_93BXpu36PXx7Meta?.path ?? ":slug(.*)*",
    meta: _91_46_46_46slug_93BXpu36PXx7Meta || {},
    alias: _91_46_46_46slug_93BXpu36PXx7Meta?.alias || [],
    redirect: _91_46_46_46slug_93BXpu36PXx7Meta?.redirect,
    component: () => import("/opt/burinka/pages/dokumenty-ke-stazeni/[...slug].vue").then(m => m.default || m)
  },
  {
    name: index06DysLDIPbMeta?.name ?? "dokumenty-ke-stazeni",
    path: index06DysLDIPbMeta?.path ?? "",
    meta: index06DysLDIPbMeta || {},
    alias: index06DysLDIPbMeta?.alias || [],
    redirect: index06DysLDIPbMeta?.redirect,
    component: () => import("/opt/burinka/pages/dokumenty-ke-stazeni/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index0ZwhCR1k0XMeta?.name ?? "index",
    path: index0ZwhCR1k0XMeta?.path ?? "/",
    meta: index0ZwhCR1k0XMeta || {},
    alias: index0ZwhCR1k0XMeta?.alias || [],
    redirect: index0ZwhCR1k0XMeta?.redirect,
    component: () => import("/opt/burinka/pages/index.vue").then(m => m.default || m)
  },
  {
    name: kategorie_45castych_45dotazuFRLkiaWffeMeta?.name ?? undefined,
    path: kategorie_45castych_45dotazuFRLkiaWffeMeta?.path ?? "/kategorie-castych-dotazu",
    meta: kategorie_45castych_45dotazuFRLkiaWffeMeta || {},
    alias: kategorie_45castych_45dotazuFRLkiaWffeMeta?.alias || [],
    redirect: kategorie_45castych_45dotazuFRLkiaWffeMeta?.redirect,
    component: () => import("/opt/burinka/pages/kategorie-castych-dotazu.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93Qk5My755wgMeta?.name ?? "kategorie-castych-dotazu-slug",
    path: _91slug_93Qk5My755wgMeta?.path ?? ":slug()",
    meta: _91slug_93Qk5My755wgMeta || {},
    alias: _91slug_93Qk5My755wgMeta?.alias || [],
    redirect: _91slug_93Qk5My755wgMeta?.redirect,
    component: () => import("/opt/burinka/pages/kategorie-castych-dotazu/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexwjvByEVt5wMeta?.name ?? "kategorie-castych-dotazu",
    path: indexwjvByEVt5wMeta?.path ?? "",
    meta: indexwjvByEVt5wMeta || {},
    alias: indexwjvByEVt5wMeta?.alias || [],
    redirect: indexwjvByEVt5wMeta?.redirect,
    component: () => import("/opt/burinka/pages/kategorie-castych-dotazu/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: dotaznikggW6pfJUKpMeta?.name ?? "rekonstrukce-domov-pro-budoucnost-dotaznik",
    path: dotaznikggW6pfJUKpMeta?.path ?? "/rekonstrukce/domov-pro-budoucnost/dotaznik",
    meta: dotaznikggW6pfJUKpMeta || {},
    alias: dotaznikggW6pfJUKpMeta?.alias || [],
    redirect: dotaznikggW6pfJUKpMeta?.redirect,
    component: () => import("/opt/burinka/pages/rekonstrukce/domov-pro-budoucnost/dotaznik.vue").then(m => m.default || m)
  },
  {
    name: slovnik_45pojmuabByRbjXFuMeta?.name ?? undefined,
    path: slovnik_45pojmuabByRbjXFuMeta?.path ?? "/slovnik-pojmu",
    meta: slovnik_45pojmuabByRbjXFuMeta || {},
    alias: slovnik_45pojmuabByRbjXFuMeta?.alias || [],
    redirect: slovnik_45pojmuabByRbjXFuMeta?.redirect,
    component: () => import("/opt/burinka/pages/slovnik-pojmu.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93SHuGnFga6NMeta?.name ?? "slovnik-pojmu-slug",
    path: _91slug_93SHuGnFga6NMeta?.path ?? ":slug()",
    meta: _91slug_93SHuGnFga6NMeta || {},
    alias: _91slug_93SHuGnFga6NMeta?.alias || [],
    redirect: _91slug_93SHuGnFga6NMeta?.redirect,
    component: () => import("/opt/burinka/pages/slovnik-pojmu/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexSpCe75sAQYMeta?.name ?? "slovnik-pojmu",
    path: indexSpCe75sAQYMeta?.path ?? "",
    meta: indexSpCe75sAQYMeta || {},
    alias: indexSpCe75sAQYMeta?.alias || [],
    redirect: indexSpCe75sAQYMeta?.redirect,
    component: () => import("/opt/burinka/pages/slovnik-pojmu/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexPnnjNhzKYsMeta?.name ?? "tiskove-zpravy",
    path: indexPnnjNhzKYsMeta?.path ?? "/tiskove-zpravy",
    meta: indexPnnjNhzKYsMeta || {},
    alias: indexPnnjNhzKYsMeta?.alias || [],
    redirect: indexPnnjNhzKYsMeta?.redirect,
    component: () => import("/opt/burinka/pages/tiskove-zpravy/index.vue").then(m => m.default || m)
  },
  {
    name: indexokz4o3QXYxMeta?.name ?? "vyhledavani",
    path: indexokz4o3QXYxMeta?.path ?? "/vyhledavani",
    meta: indexokz4o3QXYxMeta || {},
    alias: indexokz4o3QXYxMeta?.alias || [],
    redirect: indexokz4o3QXYxMeta?.redirect,
    component: () => import("/opt/burinka/pages/vyhledavani/index.vue").then(m => m.default || m)
  },
  {
    name: indexU9qKqWDOY3Meta?.name ?? "vyhody-od-burinky",
    path: indexU9qKqWDOY3Meta?.path ?? "/vyhody-od-burinky",
    meta: indexU9qKqWDOY3Meta || {},
    alias: indexU9qKqWDOY3Meta?.alias || [],
    redirect: indexU9qKqWDOY3Meta?.redirect,
    component: () => import("/opt/burinka/pages/vyhody-od-burinky/index.vue").then(m => m.default || m)
  }
]