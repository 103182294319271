interface RedirectItem {
  from: string;
  to: string;
  statusCode: number;
}

export default <RedirectItem[]>[
  {
    from: '/uver-bez-zajisteni/',
    to: '/uvery-na-bydleni/uver-od-burinky/',
    statusCode: 301
  },
  {
    from: '/hypouver/',
    to: '/uvery-na-bydleni/hypouver/',
    statusCode: 301
  },
  {
    from: '/uver-ze-stavebni-sporeni/',
    to: '/uvery-na-bydleni/uver-ze-stavebniho-sporeni/',
    statusCode: 301
  },
  {
    from: '/uver-pro-pravnicke-osoby/',
    to: '/uver-pro-bytova-druzstva-a-svj/',
    statusCode: 301
  },
  {
    from: '/druzstevni-bydleni/',
    to: '/uvery-na-bydleni/uver-na-druzstevni-bydleni/',
    statusCode: 301
  },
  {
    from: '/co-vsechno-lze-financovat/',
    to: '/uvery-na-bydleni/uver-na-rekonstrukci/',
    statusCode: 301
  },
  {
    from: '/pobocky-a-hypotecni-centra/',
    to: 'https://www.csas.cz/cs/pobocky-a-bankomaty#/?type=BRANCH&flags=POBOCKY',
    statusCode: 301
  },
  {
    from: '/specialni-nabidky/',
    to: '/vyhody-od-burinky/',
    statusCode: 301
  },
  {
    from: '/nahravani-fotografii-k-uveru/',
    to: '/dolozeni-podkladu-online/dolozeni-fotek-pred-a-po-k-uveru-na-rekonstrukci-ci-vystavbu/',
    statusCode: 301
  },
  {
    from: '/zaslani-podkladu-pro-uver/',
    to: '/dolozeni-podkladu-online/dolozeni-papirovych-podkladu-k-uveru-na-rekonstrukci-ci-vystavbu/',
    statusCode: 301
  },
  {
    from: '/zaslani-dokumentu-pro-aktivaci-online-smlouvy/',
    to: '/dolozeni-podkladu-online/dolozeni-dokladu-k-aktivaci-stavebniho-sporeni/',
    statusCode: 301
  },
  {
    from: '/klientskazona/',
    to: '/aktualizace-kontaktnich-udaju/',
    statusCode: 301
  },
  {
    from: '/blog/',
    to: '/rekonstrukce/',
    statusCode: 301
  },
  {
    from: '/formular-pro-ucely-pravidelnych-revizi/',
    to: '/dolozeni-podkladu-online/dolozeni-podkladu-o-hospodareni-pro-bytova-druzstva-a-svj/',
    statusCode: 301
  },
  {
    from: '/pojisteni-vkladu/',
    to: '/media/2019/10/informacni-prehled-o-pojisteni-vkladu.pdf',
    statusCode: 301
  },
  {
    from: '/formulare-revize/',
    to: '/dolozeni-podkladu-online/dolozeni-podkladu-o-hospodareni-pro-bytova-druzstva-a-svj/',
    statusCode: 301
  },
  {
    from: '/formulare/',
    to: '/dokumenty-ke-stazeni/formulare',
    statusCode: 301
  },
  {
    from: '/navody/',
    to: '/dokumenty-ke-stazeni/navody-jak-na-to',
    statusCode: 301
  },
  {
    from: '/osobniudaje/',
    to: '/osobni-udaje/',
    statusCode: 301
  },
  {
    from: '/uver-ze-stavebniho-sporeni/',
    to: '/uvery-na-bydleni/uver-ze-stavebniho-sporeni/',
    statusCode: 301
  },
  {
    from: '/refinancovani/',
    to: '/uvery-na-bydleni/refinancovani-a-slouceni-uveru/',
    statusCode: 301
  },
  {
    from: '/cerpani-v-georgi/',
    to: '/media/2021/11/cerpani-v-georgi.pdf',
    statusCode: 301
  },
  {
    from: '/vypisy-v-georgi/',
    to: '/poradna/nastaveni-vypisu-a-komunikace-do-george/',
    statusCode: 301
  },
  {
    from: '/kotlikova-dotace/',
    to: '/',
    statusCode: 301
  },
  {
    from: '/druzstevni-bydleni/',
    to: '/uvery-na-bydleni/uver-na-druzstevni-bydleni/',
    statusCode: 301
  },
  {
    from: '/?page_id=10398',
    to: '/',
    statusCode: 301
  },
  {
    from: '/slunecni-elektrarny-financovani-burinka/',
    to: '/uvery-na-bydleni/uver-od-burinky-pro-budoucnost/uver-na-fotovoltaiku/',
    statusCode: 301
  },
  {
    from: '/formular-pro-ucely-pravidelnych-revizi/',
    to: '/dolozeni-podkladu-online/dolozeni-podkladu-o-hospodareni-pro-bytova-druzstva-a-svj/',
    statusCode: 301
  },
  {
    from: '/na-parapetu-zdarma-se-to-odpociva/',
    to: '/',
    statusCode: 301
  },
  {
    from: '/lhuty-platebniho-styku-pred-koncem-roku-2019/',
    to: '/',
    statusCode: 301
  },
  {
    from: '/klientskazona/',
    to: '/aktualizace-kontaktnich-udaju/',
    statusCode: 301
  },
  {
    from: '/stavebni-sporeni-s-odmenou/',
    to: '/stavebni-sporeni/',
    statusCode: 301
  },
  {
    from: '/stavebkem-krok-za-krokem/',
    to: '/media/2020/10/stavebkem-krok-za-krokem.pdf',
    statusCode: 301
  },
  {
    from: '/opatreni-a-aktivity-ktere-by-vam-mely-pomoci-stavajici-slozitou-situaci-zvladnout/',
    to: '/',
    statusCode: 301
  },
  {
    from: '/covid/',
    to: '/',
    statusCode: 301
  },
  {
    from: '/odklad-splatek-svych-uveru-v-ramci-tzv-vladniho-moratoria-ochranne-lhuty/',
    to: '/',
    statusCode: 301
  },
  {
    from: '/stavebko-online/',
    to: '/stavebni-sporeni/',
    statusCode: 301
  },
  {
    from: '/3d-tisk-domu-a-burinka/',
    to: '/o-nas/spolecenska-odpovednost/inovace-od-burinky/',
    statusCode: 301
  },
  {
    from: '/prvok-od-burinky-prvni-3d-tisteny-dum-v-cr/',
    to: '/o-nas/spolecenska-odpovednost/inovace-od-burinky/',
    statusCode: 301
  },
  {
    from: '/mene-odpadu-a-nizsi-naklady-diky-3d-tisku/',
    to: '/o-nas/spolecenska-odpovednost/inovace-od-burinky/',
    statusCode: 301
  },
  {
    from: '/kdy-a-kde-prvoka-muzete-videt/',
    to: '/o-nas/spolecenska-odpovednost/inovace-od-burinky/',
    statusCode: 301
  },
  {
    from: '/inovace-od-burinky/',
    to: '/o-nas/spolecenska-odpovednost/inovace-od-burinky/',
    statusCode: 301
  },
  {
    from: '/sleva-casopis/',
    to: '/',
    statusCode: 301
  },
  {
    from: '/potvrzeni-o-provedeni-platby/',
    to: '/',
    statusCode: 301
  },
  {
    from: '/burinka-zpracovala-od-dubna-do-konce-zari-letosniho-roku-pres-11-000-zadosti-o-odklad-splaceni-nyni-tato-moznost-konci-a-klienti-zacnou-od-listopadu-postupne-prebirat-zpet-na-sva-bedra-povinnosti-sou/',
    to: '/',
    statusCode: 301
  },
  {
    from: '/rozdily-odkladu-splaceni-v-rezimu-burinky-a-vladniho-moratoria/',
    to: '/',
    statusCode: 301
  },
  {
    from: '/ceniky/kalkulacka-pro-predcasne-splaceni/',
    to: '/kalkulacka-pro-predcasne-splaceni/',
    statusCode: 301
  },
  {
    from: '/ceniky/',
    to: '/dokumenty-ke-stazeni/ceniky-a-sdeleni',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/mohu-uver-splatit-predcasne/',
    to: '/caste-dotazy/mohu-pujcku-predcasne-splatit',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/co-se-stane-kdyz-nebudu-schopen-uver-splacet/',
    to: '/caste-dotazy/jak-mam-postupovat-v%E2%80%AFpripade-neschopnosti-splacet-pujcku',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/jake-dokumenty-budu-potrebovat/',
    to: '/caste-dotazy/jake-dokumenty-budu-potrebovat-pri-zadosti-o-pujcku',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/kdyz-dokladam-ucelovost-uveru-musim-dolozit-originaly-faktur-a-dalsich-dokladu/',
    to: '/caste-dotazy/musim-k-pujcce-na-rekonstrukci-a-vystavbu-nemovitosti-dokladat-originaly-faktur',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/na-jake-cislo-uctu-mam-splacet-hypouver-od-burinky/',
    to: '/caste-dotazy/kde-najdu-cislo-uveroveho-uctu-na-ktery-mam-posilat-splatky',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/jake-nalezitosti-musi-mit-predkladane-danove-doklady/',
    to: '/caste-dotazy/jake-nalezitosti-musi-obsahovat-predkladane-danove-doklady',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/jakym-zpusobem-mohu-dolozit-danove-doklady/',
    to: '/caste-dotazy/jak-mohu-predkladat-danove-doklady',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/co-je-to-cerpani-formou-zpetna-uhrada/',
    to: '/caste-dotazy/co-je-to-cerpani-formou-zpetne-uhrady',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/mohu-od-uverove-smlouvy-odstoupit-pokud-ano-jakym-zpusobem-a-v-jake-lhute-mohu-od-smlouvy-odstoupit/',
    to: '/caste-dotazy/jakym-zpusobem-mohu-od-uverove-smlouvy-odstoupit',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/jak-je-mozne-penize-cerpat/',
    to: '/caste-dotazy/jakym-zpusobem-mohu-penize-z-pujcky-cerpat',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/kdyz-podam-platebni-prikaz-k-cerpani-uveru-za-jak-dlouho-mi-penize-odeslete-obdrzim-nejake-potvrzeni-o-tom-ze-bylo-cerpani-uveru-provedeno/',
    to: '/caste-dotazy/za-jak-dlouho-po-podani-platebniho-prikazu-budu-mit-penize-k-dispozici-a-dostanu-nejake-potvrzeni-ze-doslo-k-cerpani-uveru',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/na-jake-cislo-uctu-mam-splacet-uver/',
    to: '/caste-dotazy/kde-najdu-cislo-uveroveho-uctu-na-ktery-mam-posilat-splatky',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/od-kdy-mam-zacit-uver-splacet/',
    to: '/caste-dotazy/kdy-mam-zacit-uver-splacet',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/s-uverem-jsem-si-sjednal-zasilani-informaci-sms-nebo-e-mailem-jake-informace-budu-dostavat/',
    to: '/caste-dotazy/s-uverem-jsem-si-sjednal-zasilani-informaci-sms-nebo-e-mailem-jake-informace-budu-dostavat',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/jakou-formou-mohu-uver-splacet/',
    to: '/caste-dotazy/jak-mam-pujcku-splacet',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/jake-podminky-musim-pro-ziskani-uveru-ze-stavebniho-sporeni-splnit/',
    to: '/caste-dotazy/jakym-zpusobem-mohu-penize-z-pujcky-cerpastavebni-sporenit',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/potrebuji-pro-ziskani-uveru-od-burinky-nejakou-zastavu/',
    to: '/caste-dotazy/co-znamena-pujcka-bez-zastavy-nemovitosti',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/jak-mohu-nahlasit-zmenu-osobnich-a-kontaktnich-udaju-napr-nove-prijmeni-a-adresu/',
    to: '/caste-dotazy/jak-mohu-zmenit-sve-osobni-a-kontaktni-udaje-2',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/jak-mam-postupovat-pri-platbe-z-bankovniho-uctu/',
    to: '/caste-dotazy/jak-mam-postupovat-pri-platbe-z-bankovniho-uctu',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/jak-mam-postupovat-pri-platbe-v-hotovosti/',
    to: '/caste-dotazy/jak-mam-postupovat-pri-platbe-v-hotovosti',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/jak-mam-postupovat-pri-platbe-postovni-poukazkou/',
    to: '/kategorie-castych-dotazu/stavebni-sporeni',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/jak-provest-platbu-ze-zahranici/',
    to: '/caste-dotazy/mohu-na-svuj-ucet-stavebniho-sporeni-poslat-platbu-ze-zahranici',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/jak-vyridit-stiznost-ci-reklamaci-vztahujici-se-k-platebnimu-styku/',
    to: '/caste-dotazy/chci-podat-reklamaci-nebo-stiznost-jak-mam-postupovat',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/jak-probiha-reklamace/',
    to: '/caste-dotazy/chci-podat-reklamaci-nebo-stiznost-jak-mam-postupovat',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/co-je-treba-uvest/',
    to: '/caste-dotazy/chci-podat-reklamaci-nebo-stiznost-jak-mam-postupovat',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/jake-jsou-lhuty-k-vyrizeni-reklamace/',
    to: '/caste-dotazy/chci-podat-reklamaci-nebo-stiznost-jak-mam-postupovat',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/co-kdyz-nesouhlasite-s-vysledkem-reklamace/',
    to: '/caste-dotazy/jak-mam-postupovat-kdyz-nesouhlasim-s%E2%80%AFvysledkem-sve-reklamace',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/burinka-jde-s-kuzi-na-trh-klienti-nas-verejne-hodnoti/',
    to: '/caste-dotazy/',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/jakym-zpusobem-mam-dolozit-ucel-nezajisteneho-uveru-ktery-jste-mi-poskytli/',
    to: '/caste-dotazy/jak-mohu-predkladat-danove-doklady',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/mohu-uver-pouzit-pouze-na-rekonstrukci/',
    to: '/caste-dotazy/na-co-mohu-pouzit-penize-z%E2%80%AFuveru-na-rekonstrukci',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/jak-budu-dokladat-ucelove-pouziti-poskytnutych-prostredku/',
    to: '/caste-dotazy/musim-k-pujcce-na-rekonstrukci-a-vystavbu-nemovitosti-dokladat-originaly-faktur',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/nejsem-klientem-stavebni-sporitelny-cs-je-to-problem/',
    to: '/caste-dotazy/chci-pujcku-od-burinky-ale-nejsem-klientem-ceske-sporitelny-je-to-problem',
    statusCode: 301
  },
  {
    from: '/?post_type=faq&p=10061',
    to: '/caste-dotazy/',
    statusCode: 301
  },
  {
    from: '/?post_type=faq&p=10062',
    to: '/caste-dotazy/',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/uver-je-schvaleny-ale-penize-mi-do-15-minut-na-ucet-neprisly-co-mam-delat/',
    to: '/caste-dotazy/co-delat-kdyz-po-schvaleni-a-podepsani-pujcky-v%E2%80%AFgeorgi-neprisly-do-15-minut-penize-na-ucet',
    statusCode: 301
  },
  {
    from: '/?post_type=faq&p=10066',
    to: '/caste-dotazy/',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/co-je-to-george/',
    to: '/caste-dotazy/co-je-to-george',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/co-je-to-appka-george/',
    to: '/caste-dotazy/co-je-to-george',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/kdy-vznikne-narok-na-uver-ze-stavebniho-sporeni/',
    to: '/caste-dotazy/kdy-mohu-pozadat-o-uver-ze-sveho-stavebniho-sporeni',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/video-jak-na-uver-v-60-sekundach/',
    to: '/caste-dotazy/',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/dostal-jsem-se-do-problematicke-financni-situace-jak-mohu-postupovat/',
    to: '/caste-dotazy/neni-v-mych-moznostech-splacet-pujcku-jak-mam-postupovat',
    statusCode: 301
  },
  {
    from: '/pojem/aktontace/',
    to: '/slovnik-pojmu/akontace/',
    statusCode: 301
  },
  {
    from: '/pojem/aktivni-ucet/',
    to: '/slovnik-pojmu/aktivni-ucet/',
    statusCode: 301
  },
  {
    from: '/pojem/anuita/',
    to: '/slovnik-pojmu/anuitni-splatka/',
    statusCode: 301
  },
  {
    from: '/pojem/bodove-hodnoceni/',
    to: '/slovnik-pojmu/bodove-hodnoceni/',
    statusCode: 301
  },
  {
    from: '/pojem/bonita/',
    to: '/slovnik-pojmu/bonita/',
    statusCode: 301
  },
  {
    from: '/pojem/bytova-potreba/',
    to: '/slovnik-pojmu/bytova-potreba/',
    statusCode: 301
  },
  {
    from: '/pojem/cenik/',
    to: '/slovnik-pojmu/cenik/',
    statusCode: 301
  },
  {
    from: '/pojem/cilova-castka/',
    to: '/slovnik-pojmu/cilova-castka/',
    statusCode: 301
  },
  {
    from: '/pojem/dluznik/',
    to: '/slovnik-pojmu/dluznik/',
    statusCode: 301
  },
  {
    from: '/pojem/doba-sporeni/',
    to: '/slovnik-pojmu/doba-sporeni/',
    statusCode: 301
  },
  {
    from: '/pojem/druhdruzka/',
    to: '/slovnik-pojmu/druh-druzka/',
    statusCode: 301
  },
  {
    from: '/pojem/jistina/',
    to: '/slovnik-pojmu/jistina/',
    statusCode: 301
  },
  {
    from: '/pojem/komunikacni-ucet/',
    to: '/slovnik-pojmu/komunikacni-ucet/',
    statusCode: 301
  },
  {
    from: '/pojem/mimoradna-splatka/',
    to: '/slovnik-pojmu/mimoradna-splatka/',
    statusCode: 301
  },
  {
    from: '/pojem/mimoradny-vklad/',
    to: '/slovnik-pojmu/mimoradny-vklad/',
    statusCode: 301
  },
  {
    from: '/pojem/minimalni-mesicni-splatka/',
    to: '/slovnik-pojmu/minimalni-mesicni-splatka/',
    statusCode: 301
  },
  {
    from: '/pojem/nabidka-uveru/',
    to: '/slovnik-pojmu/nabidka-uveru/',
    statusCode: 301
  },
  {
    from: '/pojem/navrh-na-uzavreni-smlouvy-o-stavebnim-sporeni/',
    to: '/slovnik-pojmu/navrh-na-uzavreni-smlouvy-o-stavebnim-sporeni/',
    statusCode: 301
  },
  {
    from: '/pojem/nezajisteny-uver/',
    to: '/slovnik-pojmu/nezajisteny-uver-bez-zastavy-nemovitosti/',
    statusCode: 301
  },
  {
    from: '/pojem/obchodni-misto/',
    to: '/slovnik-pojmu/obchodni-misto/',
    statusCode: 301
  },
  {
    from: '/pojem/opatrovnicky-soud/',
    to: '/slovnik-pojmu/opatrovnicky-soud/',
    statusCode: 301
  },
  {
    from: '/pojem/opatrovnik/',
    to: '/slovnik-pojmu/opatrovnik/',
    statusCode: 301
  },
  {
    from: '/pojem/osoba-blizka/',
    to: '/slovnik-pojmu/osoba-blizka/',
    statusCode: 301
  },
  {
    from: '/pojem/p-a/',
    to: '/slovnik-pojmu/p-a/',
    statusCode: 301
  },
  {
    from: '/pojem/pojisteni-vkladu/',
    to: '/slovnik-pojmu/pojisteni-vkladu/',
    statusCode: 301
  },
  {
    from: '/pojem/preklenovaci-uver/',
    to: '/slovnik-pojmu/preklenovaci-uver/',
    statusCode: 301
  },
  {
    from: '/pojem/presporeni-cilove-castky/',
    to: '/slovnik-pojmu/presporeni-cilove-castky/',
    statusCode: 301
  },
  {
    from: '/pojem/rezervacni-doba-rezervace-uveru/',
    to: '/slovnik-pojmu/rezervace-uveru-ze-stavebniho-sporeni/',
    statusCode: 301
  },
  {
    from: '/pojem/rpsn/',
    to: '/slovnik-pojmu/rpsn/',
    statusCode: 301
  },
  {
    from: '/pojem/ruceni/',
    to: '/slovnik-pojmu/ruceni/',
    statusCode: 301
  },
  {
    from: '/pojem/rucitel/',
    to: '/slovnik-pojmu/rucitel/',
    statusCode: 301
  },
  {
    from: '/pojem/sazebnik-uhrad-pro-bankovni-obchody-nove-cenik/',
    to: '/slovnik-pojmu/',
    statusCode: 301
  },
  {
    from: '/pojem/splatka/',
    to: '/slovnik-pojmu/splatka/',
    statusCode: 301
  },
  {
    from: '/pojem/spoludluznik/',
    to: '/slovnik-pojmu/spoludluznik/',
    statusCode: 301
  },
  {
    from: '/pojem/statni-podpora/',
    to: '/slovnik-pojmu/statni-podpora/',
    statusCode: 301
  },
  {
    from: '/pojem/veritel/',
    to: '/slovnik-pojmu/veritel/',
    statusCode: 301
  },
  {
    from: '/pojem/vinkulace-vyplaty-pojistneho-plneni/',
    to: '/slovnik-pojmu/vinkulace-vyplaty-pojistneho-plneni/',
    statusCode: 301
  },
  {
    from: '/pojem/vinkulace-vyplaty-vkladu/',
    to: '/slovnik-pojmu/vinkulace-vyplaty-vkladu/',
    statusCode: 301
  },
  {
    from: '/pojem/vklad/',
    to: '/slovnik-pojmu/vklad/',
    statusCode: 301
  },
  {
    from: '/pojem/vseobecne-obchodni-podminky/',
    to: '/slovnik-pojmu/vseobecne-obchodni-podminky/',
    statusCode: 301
  },
  {
    from: '/pojem/zajisteny-uver/',
    to: '/slovnik-pojmu/zajisteny-uver/',
    statusCode: 301
  },
  {
    from: '/pojem/zakon-o-stavebnim-sporeni-pojem/',
    to: '/slovnik-pojmu/zakon-o-stavebnim-sporeni/',
    statusCode: 301
  },
  {
    from: '/pojem/zakonny-zastupce/',
    to: '/slovnik-pojmu/zakonny-zastupce/',
    statusCode: 301
  },
  {
    from: '/pojem/zastavce/',
    to: '/slovnik-pojmu/zastavce/',
    statusCode: 301
  },
  {
    from: '/pojem/zastavni-pravo/',
    to: '/slovnik-pojmu/zastavni-pravo/',
    statusCode: 301
  },
  {
    from: '/pojem/zustatek-na-uctu/',
    to: '/slovnik-pojmu/zustatek-na-uctu/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/prumerna-cena-zdroju/',
    to: '/dokumenty-ke-stazeni/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vob-2011/',
    to: '/dokumenty-ke-stazeni/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vob-2012/',
    to: '/dokumenty-ke-stazeni/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vob-2013/',
    to: '/dokumenty-ke-stazeni/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vob-2014/',
    to: '/dokumenty-ke-stazeni/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vob-2015/',
    to: '/dokumenty-ke-stazeni/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vob-2016/',
    to: '/dokumenty-ke-stazeni/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-3-q-2004-podle-vyhlasky-cnb-c-1632014-sb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-31-12-2004-podle-opatreni-cnb-c-1-ze-dne-30-12-2003/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-31-3-2005-podle-opatreni-cnb-c-1-ze-dne-30-12-2003/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-30-6-2005-podle-opatreni-cnb-c-1-ze-dne-30-12-2003/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-30-9-2005-podle-opatreni-cnb-c-1-ze-dne-30-12-2003/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-31-12-2005-podle-opatreni-cnb-c-1-ze-dne-30-12-2003/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-31-3-2006-podle-opatreni-cnb-c-1-ze-dne-30-12-2003/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-30-6-2006-podle-opatreni-cnb-c-1-ze-dne-30-12-2003/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-30-9-2006-podle-opatreni-cnb-c-1-ze-dne-30-12-2003/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-31-12-2006-podle-opatreni-cnb-c-1-ze-dne-30-12-2003/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-31-3-2007-podle-opatreni-cnb-c-1-ze-dne-30-12-2003/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-30-6-2007-podle-opatreni-cnb-c-1-ze-dne-30-12-2003/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-30-9-2007-podle-vyhlasky-cnb-c-1232007-sb-pdf-346-kb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-31-12-2007-podle-vyhlasky-cnb-c-1232007-sb-pdf-719-kb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-31-3-2008-podle-vyhlasky-cnb-c-1232007-sb-pdf-776-kb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-30-6-2008-podle-vyhlasky-cnb-c-1232007-sb-pdf-841-kb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-30-9-2008-podle-vyhlasky-cnb-c-1232007-sb-pdf-842-kb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-31-12-2008-podle-vyhlasky-cnb-c-1232007-sb-pdf-912-kb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-31-3-2009-podle-vyhlasky-cnb-c-1232007-sb-pdf-910-kb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-30-6-2009-podle-vyhlasky-cnb-c-1232007-sb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-30-9-2009-podle-vyhlasky-cnb-c-1232007-sb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-31-12-2009-podle-vyhlasky-cnb-c-1232007-sb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-31-3-2010-podle-vyhlasky-cnb-c-1232007-sb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-30-6-2010-podle-vyhlasky-cnb-c-1232007-sb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-30-9-2010-podle-vyhlasky-cnb-c-1232007-sb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-31-12-2010-podle-vyhlasky-cnb-c-1232007-sb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-31-3-2011-podle-vyhlasky-cnb-c-1232007-sb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-30-6-2011-podle-vyhlasky-cnb-c-1232007-sb-pdf-936-kb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-30-9-2011-podle-vyhlasky-cnb-c-1232007-sb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-31-12-2011-podle-vyhlasky-cnb-c-1232007-sb-pdf-195-kb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-31-3-2012-podle-vyhlasky-cnb-c-1232007-sb-pdf-214-kb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-30-6-2012-podle-vyhlasky-cnb-c-1232007-sb-pdf-303-kb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-30-9-2012-podle-vyhlasky-cnb-c-1232007-sb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-31-12-2012-podle-vyhlasky-cnb-c-1232007-sb-pdf-142-kb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-31-3-2013-podle-vyhlasky-cnb-c-1232007-sb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-30-6-2013-podle-vyhlasky-cnb-c-1232007-sb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-30-9-2013-podle-vyhlasky-cnb-c-1232007-sb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-31-12-2013-podle-vyhlasky-cnb-c-1232007-sb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-31-3-2014-podle-vyhlasky-cnb-c-232014-sb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-30-6-2014-podle-vyhlasky-cnb-c-232014-sb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-30-9-2014-podle-vyhlasky-cnb-c-1632014-sb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-31-12-2014-podle-vyhlasky-cnb-c-1632014-sb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-31-3-2015-podle-vyhlasky-cnb-c-1632014-sb-zip-660-kb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-30-6-2015-podle-vyhlasky-cnb-c-1632014-sb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-30-9-2015-podle-vyhlasky-cnb-c-1632014-sb-zip-687-kb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-31-12-2015-podle-vyhlasky-cnb-c-1632014-sb-zip-667-kb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-31-3-2016-podle-vyhlasky-cnb-c-1632014-sb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-30-6-2016-podle-vyhlasky-cnb-c-1632014-sb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-30-9-2016-podle-vyhlasky-cnb-c-1632014-sb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-sscs-k-31-12-2016-podle-vyhlasky-cnb-c-1632014-sb/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-za-i-q-2017/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-za-ii-q-2017/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-za-iii-q-2017/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vyrocni-zprava-za-rok-2000/',
    to: '/o-nas/povinne-uverejnovane-informace/vyrocni-zpravy/#ke-stazeni',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vyrocni-zprava-za-rok-2001/',
    to: '/o-nas/povinne-uverejnovane-informace/vyrocni-zpravy/#ke-stazeni',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vyrocni-zprava-za-rok-2002/',
    to: '/o-nas/povinne-uverejnovane-informace/vyrocni-zpravy/#ke-stazeni',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vyrocni-zprava-za-rok-2003/',
    to: '/o-nas/povinne-uverejnovane-informace/vyrocni-zpravy/#ke-stazeni',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vyrocni-zprava-za-rok-2004/',
    to: '/o-nas/povinne-uverejnovane-informace/vyrocni-zpravy/#ke-stazeni',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vyrocni-zprava-za-rok-2005/',
    to: '/o-nas/povinne-uverejnovane-informace/vyrocni-zpravy/#ke-stazeni',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vyrocni-zprava-za-rok-2006/',
    to: '/o-nas/povinne-uverejnovane-informace/vyrocni-zpravy/#ke-stazeni',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vyrocni-zprava-za-rok-2007/',
    to: '/o-nas/povinne-uverejnovane-informace/vyrocni-zpravy/#ke-stazeni',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vyrocni-zprava-za-rok-2008/',
    to: '/o-nas/povinne-uverejnovane-informace/vyrocni-zpravy/#ke-stazeni',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vyrocni-zprava-za-rok-2009/',
    to: '/o-nas/povinne-uverejnovane-informace/vyrocni-zpravy/#ke-stazeni',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vyrocni-zprava-za-rok-2010/',
    to: '/o-nas/povinne-uverejnovane-informace/vyrocni-zpravy/#ke-stazeni',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vyrocni-zprava-za-rok-2011/',
    to: '/o-nas/povinne-uverejnovane-informace/vyrocni-zpravy/#ke-stazeni',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vyrocni-zprava-za-rok-2012/',
    to: '/o-nas/povinne-uverejnovane-informace/vyrocni-zpravy/#ke-stazeni',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vyrocni-zprava-za-rok-2013/',
    to: '/o-nas/povinne-uverejnovane-informace/vyrocni-zpravy/#ke-stazeni',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vyrocni-zprava-za-rok-2014/',
    to: '/o-nas/povinne-uverejnovane-informace/vyrocni-zpravy/#ke-stazeni',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vyrocni-zprava-za-rok-2014-doplneni/',
    to: '/o-nas/povinne-uverejnovane-informace/vyrocni-zpravy/#ke-stazeni',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vyrocni-zprava-za-rok-2015/',
    to: '/o-nas/povinne-uverejnovane-informace/vyrocni-zpravy/#ke-stazeni',
    statusCode: 301
  },
  {
    from: '/vyrocni-zpravy/',
    to: '/o-nas/povinne-uverejnovane-informace/vyrocni-zpravy/',
    statusCode: 301
  },
  {
    from: '/?post_type=download&p=7236',
    to: '/dokumenty-ke-stazeni/',
    statusCode: 301
  },
  {
    from: '/?post_type=download&p=7238',
    to: '/dokumenty-ke-stazeni/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/7240-2/',
    to: '/dokumenty-ke-stazeni/',
    statusCode: 301
  },
  {
    from: '/?post_type=download&p=7292',
    to: '/dokumenty-ke-stazeni/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/stanovy-spolecnosti/',
    to: '/o-nas/povinne-uverejnovane-informace/informace-pro-akcionare/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/rozhodnuti-o-vykonu-funkce-clena-vyboru-pro-audit-2742017/',
    to: '/o-nas/povinne-uverejnovane-informace/informace-pro-akcionare/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/rozhodnuti-o-rozdeleni-zisku-a-o-ucetni-zaverce-27-4-2017/',
    to: '/o-nas/povinne-uverejnovane-informace/informace-pro-akcionare/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/rozhodnuti-o-rozdeleni-zisku-a-o-ucetni-zaverce-26-4-2016/',
    to: '/o-nas/povinne-uverejnovane-informace/informace-pro-akcionare/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/smlouva-o-vykonu-funkce-clena-dozorci-rady-a-clenky-vyboru-pro-audit-1-7-2015/',
    to: '/o-nas/povinne-uverejnovane-informace/informace-pro-akcionare/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/zmeny-v-dozorci-rade-spolecnosti-a-vyboru-pro-audit-1-7-2015/',
    to: '/o-nas/povinne-uverejnovane-informace/informace-pro-akcionare/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/rozhodnuti-o-rozdeleni-zisku-a-o-ucetni-zaverce-28-4-2015/',
    to: '/o-nas/povinne-uverejnovane-informace/informace-pro-akcionare/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/smlouva-o-vykonu-funkce-clena-dozorci-rady-16-4-2015/',
    to: '/o-nas/povinne-uverejnovane-informace/informace-pro-akcionare/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/zmena-v-dozorci-rade-spolecnosti-16-4-2015/',
    to: '/o-nas/povinne-uverejnovane-informace/informace-pro-akcionare/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/smlouva-o-vykonu-funkce-clena-dozorci-rady-3-3-2015/',
    to: '/o-nas/povinne-uverejnovane-informace/informace-pro-akcionare/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/volba-clenu-dozorci-rady-3-3-2015/',
    to: '/o-nas/povinne-uverejnovane-informace/informace-pro-akcionare/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/smlouva-o-vykonu-funkce-clena-vyboru-pro-audit-vzor/',
    to: '/o-nas/povinne-uverejnovane-informace/informace-pro-akcionare/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/smlouva-o-vykonu-funkce-clena-dozorci-rady-vzor/',
    to: '/o-nas/povinne-uverejnovane-informace/informace-pro-akcionare/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/zprava-o-vztazich/',
    to: '/o-nas/povinne-uverejnovane-informace/informace-pro-akcionare/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/zprava-o-podnikatelske-cinnosti-spolecnosti-a-stavu-jejiho-majetku/',
    to: '/o-nas/povinne-uverejnovane-informace/informace-pro-akcionare/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/ucetni-zaverka-k-31-12-2013/',
    to: '/o-nas/povinne-uverejnovane-informace/informace-pro-akcionare/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/pozvanka-na-valnou-hromadu/',
    to: '/o-nas/povinne-uverejnovane-informace/informace-pro-akcionare/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/agreement-on-the-performance-of-the-position-of-the-member-of-the-audit-committee-template/',
    to: '/o-nas/povinne-uverejnovane-informace/informace-pro-akcionare/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/agreement-on-the-performance-of-the-positions-of-a-member-of-the-supervisory-board-template/',
    to: '/o-nas/povinne-uverejnovane-informace/informace-pro-akcionare/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/report-on-relations/',
    to: '/o-nas/povinne-uverejnovane-informace/informace-pro-akcionare/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/financial-statements-for-year-ended-31-december-2013/',
    to: '/o-nas/povinne-uverejnovane-informace/informace-pro-akcionare/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/general-meeting-invitation/',
    to: '/o-nas/povinne-uverejnovane-informace/informace-pro-akcionare/',
    statusCode: 301
  },
  {
    from: '/informace-pro-akcionare/',
    to: '/o-nas/povinne-uverejnovane-informace/informace-pro-akcionare/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vseobecne-obchodni-podminky-ucinnost-od-2-1-2004-do-31-12-2013/',
    to: '/dokumenty-ke-stazeni/smluvni-podminky/vseobecne-obchodni-podminky',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vseobecne-obchodni-podminky-ucinnost-od-1-9-2002-do-1-1-2004/',
    to: '/dokumenty-ke-stazeni/smluvni-podminky/vseobecne-obchodni-podminky',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vseobecne-obchodni-podminky-ucinnost-od-14-9-2001-do-31-8-2002/',
    to: '/dokumenty-ke-stazeni/smluvni-podminky/vseobecne-obchodni-podminky',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vseobecne-obchodni-podminky-ucinnost-od-1-7-2000-do-13-9-2001/',
    to: '/dokumenty-ke-stazeni/smluvni-podminky/vseobecne-obchodni-podminky',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/seobecne-obchodni-podminky-ucinnost-od-15-3-1999-do-30-6-2000/',
    to: '/dokumenty-ke-stazeni/smluvni-podminky/vseobecne-obchodni-podminky',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vseobecne-obchodni-podminky-ucinnost-od-15-9-1995-do-14-3-1999/',
    to: '/dokumenty-ke-stazeni/smluvni-podminky/vseobecne-obchodni-podminky',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vseobecne-obchodni-podminky-ucinnost-od-1-7-1994-do-14-9-1995/',
    to: '/dokumenty-ke-stazeni/smluvni-podminky/vseobecne-obchodni-podminky',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/podminky-poskytovani-sluzeb-platebniho-styku-stavebni-sporitelnou-ceske-sporitelny-a-s-platne-od-22-4-2013-31-12-2013/',
    to: '/dokumenty-ke-stazeni/smluvni-podminky/podminky-platebniho-styku',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/podminky-poskytovani-sluzeb-platebniho-styku-stavebni-sporitelnou-ceske-sporitelny-a-s-platne-od-18-4-2013-22-4-2013/',
    to: '/dokumenty-ke-stazeni/smluvni-podminky/podminky-platebniho-styku',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/podminky-poskytovani-sluzeb-platebniho-styku-stavebni-sporitelnou-ceske-sporitelny-a-s-platne-od-1-9-2010-17-4-2011/',
    to: '/dokumenty-ke-stazeni/smluvni-podminky/podminky-platebniho-styku',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/podminky-poskytovani-sluzeb-platebniho-styku-stavebni-sporitelnou-ceske-sporitelny-a-s-platne-od-1-1-2010-31-8-2010/',
    to: '/dokumenty-ke-stazeni/smluvni-podminky/podminky-platebniho-styku',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/podminky-poskytovani-sluzeb-platebniho-styku-stavebni-sporitelnou-ceske-sporitelny-a-s-platne-od-1-11-2009-31-12-2009/',
    to: '/dokumenty-ke-stazeni/smluvni-podminky/podminky-platebniho-styku',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/podminky-platebniho-styku-stavebni-sporitelny-ceske-sporitelny-a-s/',
    to: '/dokumenty-ke-stazeni/smluvni-podminky/podminky-platebniho-styku',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/doplnujici-udaje-k-hospodareni-pravnicke-osoby/',
    to: '/dokumenty-ke-stazeni/formulare/uvery',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/plna-moc-pro-nezajistene-uvery-vzor/',
    to: '/dokumenty-ke-stazeni/formulare/uvery',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/plna-moc-pro-zajistene-uvery-zastavou-nemovitosti-vzor/',
    to: '/dokumenty-ke-stazeni/formulare/uvery',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/soupiska-dokladu-prokazujici-ucelove-pouziti-k-vyplneni-v-excelu/',
    to: '/dokumenty-ke-stazeni/formulare/uvery',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/soupiska-dokladu-prokazujici-ucelove-pouziti-k-vyplneni/',
    to: '/dokumenty-ke-stazeni/formulare/uvery',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vseobecne-obchodni-podminky/',
    to: '/dokumenty-ke-stazeni/smluvni-podminky/vseobecne-obchodni-podminky',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/navod-pro-overeni-platnosti-elektronickeho-podpisu/',
    to: '/dokumenty-ke-stazeni/smluvni-podminky/vseobecne-obchodni-podminky',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vzor-navrhu-na-schvaleni-pravniho-jednani-za-nezletileho-ucastnika/',
    to: '/dokumenty-ke-stazeni/formulare/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/jak-postupovat-pri-vypovedi-smlouvy-nezletileho-ucastnika/',
    to: '/dokumenty-ke-stazeni/formulare/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/plna-moc-pro-pravni-jednani-vuci-stavebni-sporitelne-ceske-sporitelny-a-s/',
    to: '/dokumenty-ke-stazeni/formulare/uvery',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-list-produktu-uver-od-burinky-final/',
    to: '/uvery-na-bydleni/uver-od-burinky/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-list-produktu-hypouver-od-burinky-v/',
    to: '/uvery-na-bydleni/hypouver/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/sdeleni-stavebni-sporitelny/',
    to: '/dokumenty-ke-stazeni/ceniky-a-sdeleni/sdeleni-stavebni-sporitelny',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/cenik/',
    to: '/dokumenty-ke-stazeni/ceniky-a-sdeleni',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/platebni-podminky/',
    to: '/dokumenty-ke-stazeni/smluvni-podminky/podminky-platebniho-styku',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/sdeleni/',
    to: '/dokumenty-ke-stazeni/ceniky-a-sdeleni/sdeleni-stavebni-sporitelny',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/soupiska-dokladu-formular/',
    to: '/dokumenty-ke-stazeni/formulare/uvery',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/uverove-podminky/',
    to: '/dokumenty-ke-stazeni/formulare/uvery',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/zadost-o-poskytnuti-uveru-ze-stavebniho-sporeni/',
    to: '/dokumenty-ke-stazeni/formulare/uvery',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/zpusob-stanoveni-uhrady-za-vedeni/',
    to: '/dokumenty-ke-stazeni/formulare/uvery',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vzorove-priklady-vypoctu-poplatku-z-mimoradne-splatky-uveru/',
    to: '/dokumenty-ke-stazeni/formulare/uvery',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/smlouva-o-vypujcce-bezplatne-uzivani-nemovite-veci/',
    to: '/dokumenty-ke-stazeni/formulare/uvery',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/souhlas-s-rekonstrukci/',
    to: '/dokumenty-ke-stazeni/formulare/uvery',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/prohlaseni-k-ucelovosti-refinancovaneho-uveru/',
    to: '/dokumenty-ke-stazeni/formulare/uvery',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/prohlaseni-o-zpusobu-uzivan-nemovite-veci-dosud-neevidovane-k-trvalemu-bydleni/',
    to: '/dokumenty-ke-stazeni/formulare/uvery',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/soupis/',
    to: '/dokumenty-ke-stazeni/formulare/uvery',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-za-iv-q-2017/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vyrocni-zprava-za-rok-2017/',
    to: '/o-nas/povinne-uverejnovane-informace/vyrocni-zpravy/#ke-stazeni',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-za-i-q-2018/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/rozhodnuti-jedineho-akcionare-25-4-2018/',
    to: '/o-nas/povinne-uverejnovane-informace/informace-pro-akcionare/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-za-ii-q-2018/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/priklady-splaceni-uveru-pri-prodeji-nemovitosti/',
    to: '/dokumenty-ke-stazeni/formulare/uvery',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/priklady-vypoctu-poplatku-za-mimoradnou-splatku-uveru-dle-skutecne-vynalozenych-nakladu/',
    to: '/dokumenty-ke-stazeni/formulare/uvery',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/jakym-zpusobem-mam-dolozit-ucel-nezajisteneho-uveru-ktery-jste-mi-poskytli/',
    to: '/dokumenty-ke-stazeni/formulare/uvery',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-za-iii-q-2018/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/potvrzeni-zamestnavatele-o-vysi-pracovniho-prijmu-zamestnance-anglicka-verze/',
    to: '/dokumenty-ke-stazeni/formulare/uvery',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/potvrzeni-zamestnavatele-o-vysi-pracovniho-prijmu-zamestnance-diety/',
    to: '/dokumenty-ke-stazeni/formulare/uvery',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/potvrzeni-zamestnavatele-o-vysi-pracovniho-prijmu-zamestnance-nemecka-verze/',
    to: '/dokumenty-ke-stazeni/formulare/uvery',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/potvrzeni-zamestnavatele-o-vysi-pracovniho-prijmu-zamestnance/',
    to: '/dokumenty-ke-stazeni/formulare/uvery',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/dispozice-pro-platebni-styk/',
    to: '/dokumenty-ke-stazeni/smluvni-podminky/podminky-platebniho-styku',
    statusCode: 301
  },
  {
    from: '/smluvni-podminky/',
    to: '/dokumenty-ke-stazeni/smluvni-podminky',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-za-iv-q-2018/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vyrocni-zprava-za-rok-2018/',
    to: '/o-nas/povinne-uverejnovane-informace/vyrocni-zpravy/#ke-stazeni',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-za-i-q-2019/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-list-produktu-hypouver-od-burinky/',
    to: '/uvery-na-bydleni/hypouver/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-za-ii-q-2019/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-prehled-o-pojisteni-vkladu/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-za-iii-q-2019/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/vyrocni-zprava-za-rok-2019/',
    to: '/o-nas/povinne-uverejnovane-informace/vyrocni-zpravy/#ke-stazeni',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-za-iv-q-2019/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-za-i-q-2020/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/oznameni-o-zmene-osobnich-a-kontaktnich-udaju/',
    to: '/dokumenty-ke-stazeni/formulare/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/rozhodnuti-jedineho-akcionare-29-4-2020/',
    to: '/o-nas/povinne-uverejnovane-informace/informace-pro-akcionare/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-za-ii-q-2020/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/plna-moc-udelovana-treti-osobe-rodicem-nezletileho-k-uzavreni-smlouvy-o-stavebnim-sporen/',
    to: '/dokumenty-ke-stazeni/formulare/',
    statusCode: 301
  },
  {
    from: '/dokumenty-ke-stazeni/informacni-povinnost-za-iii-q-2020/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/soubory/cenik.pdf',
    to: '/media/2017/12/cenik.pdf',
    statusCode: 301
  },
  {
    from: '/soubory/informacni_prehled_o_pojisteni.pdf',
    to: '/media/2017/12/informacni_prehled_o_pojisteni.pdf',
    statusCode: 301
  },
  {
    from: '/soubory/Kontakty_na_regiony.pdf',
    to: '/media/2017/12/Kontakty_na_regiony.pdf',
    statusCode: 301
  },
  {
    from: '/soubory/Platebni_podminky.pdf',
    to: '/media/2017/12/Platebni_podminky.pdf',
    statusCode: 301
  },
  {
    from: '/soubory/Platebni_prikaz.pdf',
    to: '/media/2017/12/Platebni_prikaz.pdf',
    statusCode: 301
  },
  {
    from: '/soubory/podminky_poskytovani_sluzeb.pdf',
    to: '/media/2017/12/podminky_poskytovani_sluzeb.pdf',
    statusCode: 301
  },
  {
    from: '/soubory/potvrzeni-o-prijmu-anglicka-verze.pdf',
    to: '/media/2017/12/potvrzeni-o-prijmu-anglicka-verze.pdf',
    statusCode: 301
  },
  {
    from: '/soubory/Potvrzeni_o_prijmu_bez_diet.doc',
    to: '/media/2017/12/Potvrzeni_o_prijmu_bez_diet.doc',
    statusCode: 301
  },
  {
    from: '/soubory/Potvrzeni_o_prijmu_diety.doc',
    to: '/media/2017/12/Potvrzeni_o_prijmu_diety.doc',
    statusCode: 301
  },
  {
    from: '/soubory/potvrzeni-o-prijmu-nemecka-verze.pdf',
    to: '/media/2017/12/potvrzeni-o-prijmu-nemecka-verze.pdf',
    statusCode: 301
  },
  {
    from: '/soubory/PPI_brozura.pdf',
    to: '/media/2017/12/PPI_brozura.pdf',
    statusCode: 301
  },
  {
    from: '/soubory/PPI_dotaznik_pojisteneho.pdf',
    to: '/media/2017/12/PPI_dotaznik_pojisteneho.pdf',
    statusCode: 301
  },
  {
    from: '/soubory/PPI_faktura.pdf',
    to: '/media/2017/12/PPI_faktura.pdf',
    statusCode: 301
  },
  {
    from: '/soubory/PPI_lekarska_prohlidka.pdf',
    to: '/media/2017/12/PPI_lekarska_prohlidka.pdf',
    statusCode: 301
  },
  {
    from: '/soubory/PPI_lekarska_zprava.pdf',
    to: '/media/2017/12/PPI_lekarska_zprava.pdf',
    statusCode: 301
  },
  {
    from: '/soubory/PPI_Ramcova_pojistna_smlouva.pdf',
    to: '/media/2017/12/PPI_Ramcova_pojistna_smlouva.pdf',
    statusCode: 301
  },
  {
    from: '/soubory/Predsmluvni_informace_o_uverech.pdf',
    to: '/media/2017/12/Predsmluvni_informace_o_uverech.pdf',
    statusCode: 301
  },
  {
    from: '/soubory/Sazebnik.pdf',
    to: '/media/2017/12/Sazebnik.pdf',
    statusCode: 301
  },
  {
    from: '/soubory/Sdeleni.pdf',
    to: '/media/2017/12/Sdeleni.pdf',
    statusCode: 301
  },
  {
    from: '/soubory/souhlas_se_zpracovanim_osobnich_udaju.pdf',
    to: '/media/2017/12/souhlas_se_zpracovanim_osobnich_udaju.pdf',
    statusCode: 301
  },
  {
    from: '/soubory/Soupiska_dokladu_formular.pdf',
    to: '/media/2017/12/Soupiska_dokladu_formular.pdf',
    statusCode: 301
  },
  {
    from: '/soubory/Uverove_podminky.pdf',
    to: '/media/2017/12/Uverove_podminky.pdf',
    statusCode: 301
  },
  {
    from: '/soubory/sscs-007-vseobecne-obchodni-podminky.pdf',
    to: '/media/2017/12/sscs-007-vseobecne-obchodni-podminky.pdf',
    statusCode: 301
  },
  {
    from: '/soubory/Zadost_o_poskytnuti_uveru_ze_stavebniho_sporeni_nezajisteny.doc',
    to: '/media/2017/12/Zadost_o_poskytnuti_uveru_ze_stavebniho_sporeni_nezajisteny.doc',
    statusCode: 301
  },
  {
    from: '/soubory/zadost_o_uver_od_burinky.doc',
    to: '/media/2017/12/zadost_o_uver_od_burinky.doc',
    statusCode: 301
  },
  {
    from: '/soubory/Zadost_o_uver_se_zajistenim.doc',
    to: '/media/2017/12/Zadost_o_uver_se_zajistenim.doc',
    statusCode: 301
  },
  {
    from: '/soubory/zpusob_stanoveni_uhrady_za_vedeni.pdf',
    to: '/media/2017/12/zpusob_stanoveni_uhrady_za_vedeni.pdf',
    statusCode: 301
  },
  {
    from: '/soubory/vseobecne_obchodni_podminky.pdf',
    to: '/media/2018/01/vseobecne_obchodni_podminky.pdf',
    statusCode: 301
  },
  {
    from: '/cs/produkty/uvery/typy-uveru/hypouver-od-burinky/',
    to: '/hypouver/',
    statusCode: 301
  },
  {
    from: '/cs/produkty/stavebni-sporeni/',
    to: '/stavebni-sporeni/',
    statusCode: 301
  },
  {
    from: '/cs/produkty/online-sluzby/online-smlouva/',
    to: '/online-sjednani/',
    statusCode: 301
  },
  {
    from: '/cs/produkty/online-sluzby/',
    to: '/online-sjednani/',
    statusCode: 301
  },
  {
    from: '/cs/produkty/uvery/typy-uveru/uver-ze-stavebniho-sporeni/',
    to: '/uver-ze-stavebni-sporeni/',
    statusCode: 301
  },
  {
    from: '/cs/produkty/uvery/typy-uveru/zajisteny-uver-hypo-trend/',
    to: '/uver-trend/',
    statusCode: 301
  },
  {
    from: '/cs/o-burince/zakladni-informace/informace-pro-akcionare/rozhodnuti-jedineho-akcionare/',
    to: '/o-nas/',
    statusCode: 301
  },
  {
    from: '/cs/o-burince/zakladni-informace/clenstvi-v-asociacich-a-organizacich/',
    to: '/o-nas/',
    statusCode: 301
  },
  {
    from: '/cs/o-burince/zakladni-informace/informace-pro-akcionare/valna-hromada-2014/',
    to: '/obchodni-podminky/',
    statusCode: 301
  },
  {
    from: '/de/allgemeine-geschaftsbedingungen/',
    to: '/obchodni-podminky/',
    statusCode: 301
  },
  {
    from: '/povinne-uverejnovane-informace/',
    to: '/o-nas/povinne-uverejnovane-informace/informacni-povinnost/',
    statusCode: 301
  },
  {
    from: '/cs/o-burince/zakladni-informace/povinne-uverejnovane-informace/informacni-povinnost-sscs-v-souladu-s-vyhlaskou-cnb/',
    to: '/obchodni-podminky/',
    statusCode: 301
  },
  {
    from: '/cs/o-burince/zakladni-informace/spolecenska-odpovednost/',
    to: '/o-nas/spolecenska-odpovednost/',
    statusCode: 301
  },
  {
    from: '/spolecenska-odpovednost-burinky/',
    to: '/o-nas/spolecenska-odpovednost/',
    statusCode: 301
  },
  {
    from: '/en/general-business-conditions/',
    to: '/obchodni-podminky/',
    statusCode: 301
  },
  {
    from: '/s/ecomm.shtml',
    to: '/obchodni-podminky/',
    statusCode: 301
  },
  {
    from: '/cs/o-burince/dokumenty-ke-stazeni/',
    to: '/dokumenty-ke-stazeni/',
    statusCode: 301
  },
  {
    from: '/cs/o-burince/zakladni-informace/predstaveni/',
    to: '/o-nas/',
    statusCode: 301
  },
  {
    from: '/en/about-burinka/basic-informations/information-for-shareholders/',
    to: '/obchodni-podminky/',
    statusCode: 301
  },
  {
    from: '/cs/o-burince/zakladni-informace/financni-skupina-ceske-sporitelny/',
    to: '/o-nas/o-burince/financni-skupina-ceske-sporitelny/',
    statusCode: 301
  },
  {
    from: '/financni-skupina-ceske-sporitelny/',
    to: '/o-nas/o-burince/financni-skupina-ceske-sporitelny/',
    statusCode: 301
  },
  {
    from: '/cs/kontakty/kontaktni-udaje/',
    to: '/kontakty/',
    statusCode: 301
  },
  {
    from: '/cs/o-burince/kariera/',
    to: '/o-nas/kariera/',
    statusCode: 301
  },
  {
    from: '/kariera/',
    to: '/o-nas/kariera/',
    statusCode: 301
  },
  {
    from: '/pro-media/',
    to: '/o-nas/pro-media/',
    statusCode: 301
  },
  {
    from: '/cs/o-burince/zakladni-informace/informace-pro-akcionare/',
    to: '/o-nas/',
    statusCode: 301
  },
  {
    from: '/cs/o-burince/zakladni-informace/povinne-uverejnovane-informace/vyrocni-zpravy/',
    to: '/o-nas/',
    statusCode: 301
  },
  {
    from: '/cs/o-burince/zakladni-informace/povinne-uverejnovane-informace/',
    to: '/kategorie_dokumentu/povinne-uverejnovane-informace/',
    statusCode: 301
  },
  {
    from: '/cs/kontakty/kontak-pro-media/',
    to: '/kontakty/',
    statusCode: 301
  },
  {
    from: '/cs/o-burince/zakladni-informace/organizacni-struktura/',
    to: '/o-nas/',
    statusCode: 301
  },
  {
    from: '/cs/chci-financovat/',
    to: '/co-vsechno-lze-financovat/',
    statusCode: 301
  },
  {
    from: '/cs/produkty/uvery/typy-uveru/uver-od-burinky/',
    to: '/uver-bez-zajisteni/',
    statusCode: 301
  },
  {
    from: '/srv/www/qf/cs/ramjet/unsecuredLoan2/simulation',
    to: '/uverodburinky/',
    statusCode: 301
  },
  {
    from: '/cs/caste-dotazy/co-vas-zajima-k-uverum/',
    to: '/uver-ze-stavebni-sporeni/',
    statusCode: 301
  },
  {
    from: '/srv/www/content/db/cs/aktualne/novinky/pujcime-vam-az-800-000-kc-bez-zajisteni-nemovitosti-336.html',
    to: '/pujcime-vam-az-800-000-kc-bez-zajisteni-nemovitosti/',
    statusCode: 301
  },
  {
    from: '/cs/obchodni-podminky/zakon-o-stavebnim-sporeni/03/',
    to: '/zakon-o-stavebnim-sporeni/',
    statusCode: 301
  },
  {
    from: '/cs/obchodni-podminky/zakon-o-stavebnim-sporeni/',
    to: '/zakon-o-stavebnim-sporeni/',
    statusCode: 301
  },
  {
    from: '/cs/obchodni-podminky/vseobecne-obchodni-podminky/',
    to: '/vseobecne-obchodni-podminky/',
    statusCode: 301
  },
  {
    from: '/cs/obchodni-podminky/cenik/fyzicke-osoby/',
    to: '/ceniky/',
    statusCode: 301
  },
  {
    from: '/cs/kontakty/pobocky/pobocky.shtml',
    to: '/pobocky-a-cs-poradci/',
    statusCode: 301
  },
  {
    from: '/cs/produkty/uvery/dokumenty-pro-vyrizeni-uveru/moznosti-pouziti-prostredku-stavebniho-sporeni/',
    to: '/dokumenty-ke-stazeni/',
    statusCode: 301
  },
  {
    from: '/cs/produkty/uvery/',
    to: '/uver-bez-zajisteni/',
    statusCode: 301
  },
  {
    from: '/online-sporeni/',
    to: '/online-sjednani/',
    statusCode: 301
  },
  {
    from: '/online-sporeni/',
    to: '/online-sjednani/',
    statusCode: 301
  },
  {
    from: '/dokumenty/',
    to: '/zaslani-podkladu-pro-uver/',
    statusCode: 301
  },
  {
    from: '/kotlikovky/',
    to: '/kotlikove-dotace/',
    statusCode: 301
  },
  {
    from: '/edokumenty/',
    to: '/vypisy-od-burinky-nove-v-servisu-24/',
    statusCode: 301
  },
  {
    from: '/uvery/',
    to: '/vytvorte-si-sve-kralovstvi-s-burinkou/',
    statusCode: 301
  },
  {
    from: '/uver/',
    to: '/vytvorte-si-sve-kralovstvi-s-burinkou/',
    statusCode: 301
  },
  {
    from: '/rychla_vyplata/',
    to: '/rychla-vyplata/',
    statusCode: 301
  },
  {
    from: '/vop/',
    to: '/dokumenty-ke-stazeni/smluvni-podminky/vseobecne-obchodni-podminky/',
    statusCode: 301
  },
  {
    from: '/podminky-platebniho-styku/',
    to: '/dokumenty-ke-stazeni/smluvni-podminky/podminky-platebniho-styku/',
    statusCode: 301
  },
  {
    from: '/3d-tisk-domu-a-burinka/',
    to: '/o-nas/spolecenska-odpovednost/inovace-od-burinky/',
    statusCode: 301
  },
  {
    from: '/inovace-od-burinky/nove-stranky-inovace-od-burinky/',
    to: '/o-nas/spolecenska-odpovednost/inovace-od-burinky/nove-stranky-inovace-od-burinky',
    statusCode: 301
  },
  {
    from: '/inovace-od-burinky/parkourove-hriste-od-burinky/',
    to: '/o-nas/spolecenska-odpovednost/inovace-od-burinky/parkourove-hriste-od-burinky',
    statusCode: 301
  },
  {
    from: '/inovace-od-burinky/prvok-a-jeho-vyhry/',
    to: '/o-nas/spolecenska-odpovednost/inovace-od-burinky/prvok-a-jeho-vyhry',
    statusCode: 301
  },
  {
    from: '/inovace-od-burinky/dokument-o-3d-tisku/',
    to: '/o-nas/spolecenska-odpovednost/inovace-od-burinky/dokument-o-3d-tisku',
    statusCode: 301
  },
  {
    from: '/prvok-od-burinky-prvni-3d-tisteny-dum-v-cr/',
    to: '/o-nas/spolecenska-odpovednost/inovace-od-burinky/',
    statusCode: 301
  },
  {
    from: '/mene-odpadu-a-nizsi-naklady-diky-3d-tisku/',
    to: '/o-nas/spolecenska-odpovednost/inovace-od-burinky/',
    statusCode: 301
  },
  {
    from: '/kdy-a-kde-prvoka-muzete-videt/',
    to: '/o-nas/spolecenska-odpovednost/inovace-od-burinky/',
    statusCode: 301
  },
  {
    from: '/specialni-nabidky/sulko/',
    to: '/vyhody-od-burinky/',
    statusCode: 301
  },
  {
    from: '/specialni-nabidky/zelena-pro-dum/',
    to: '/vyhody-od-burinky/',
    statusCode: 301
  },
  {
    from: '/specialni-nabidky/ivt-tepelna-cerpadla/',
    to: '/vyhody-od-burinky/',
    statusCode: 301
  },
  {
    from: '/specialni-nabidky/koupelny-ptacek/',
    to: '/vyhody-od-burinky/',
    statusCode: 301
  },
  {
    from: '/specialni-nabidky/kika/',
    to: '/vyhody-od-burinky/',
    statusCode: 301
  },
  {
    from: '/specialni-nabidky/indeco/',
    to: '/vyhody-od-burinky/',
    statusCode: 301
  },
  {
    from: '/specialni-nabidky/mk-keramika/',
    to: '/vyhody-od-burinky/',
    statusCode: 301
  },
  {
    from: '/specialni-nabidky/trido/',
    to: '/vyhody-od-burinky/',
    statusCode: 301
  },
  {
    from: '/specialni-nabidky/siko/',
    to: '/dokumenty-ke-stazeni/smluvni-podminky/vseobecne-obchodni-podminky/',
    statusCode: 301
  },
  {
    from: '/vseobecne-obchodni-podminky-archiv/',
    to: '/dokumenty-ke-stazeni/smluvni-podminky/vseobecne-obchodni-podminky/',
    statusCode: 301
  },
  {
    from: '/platebni-styk/',
    to: '/dokumenty-ke-stazeni/smluvni-podminky/podminky-platebniho-styku/',
    statusCode: 301
  },
  {
    from: '/kalkulacky/',
    to: '/',
    statusCode: 301
  },
  {
    from: '/sdeleni/',
    to: '/dokumenty-ke-stazeni/ceniky-a-sdeleni/sdeleni-stavebni-sporitelny/',
    statusCode: 301
  },
  {
    from: '/ceniky/archiv-ceniku-pro-fyzicke-osoby/',
    to: '/dokumenty-ke-stazeni/ceniky-a-sdeleni/cenik-pro-fyzicke-osoby/',
    statusCode: 301
  },
  {
    from: '/ceniky/archiv-ceniku-pro-pravnicke-osoby/',
    to: '/dokumenty-ke-stazeni/ceniky-a-sdeleni/cenik-pro-pravnicke-osoby/',
    statusCode: 301
  },
  {
    from: '/formular-pro-online-sjednani-uzivatel/',
    to: '/formular-pro-online-sjednani/',
    statusCode: 301
  },
  {
    from: '/formular-pro-online-sjednani-na-pobocce/',
    to: '/formular-pro-online-sjednani/',
    statusCode: 301
  },
  {
    from: '/archiv-ceniku-pro-pravnicke-osoby/',
    to: '/dokumenty-ke-stazeni/ceniky-a-sdeleni/cenik-pro-fyzicke-osoby/',
    statusCode: 301
  },
  {
    from: '/archiv-ceniku-pro-fyzicke-osoby/',
    to: '/dokumenty-ke-stazeni/ceniky-a-sdeleni/cenik-pro-pravnicke-osoby/',
    statusCode: 301
  },
  {
    from: '/akce-oresi/',
    to: '/',
    statusCode: 301
  },
  {
    from: '/s-uverem-od-burinky-ziskate-az-50-slevu-na-kuchyne-oresi/',
    to: '/',
    statusCode: 301
  },
  {
    from: '/aktuality/sleva-na-uzavreni-stavebniho-sporeni-v-akci-cihla/',
    to: '/',
    statusCode: 301
  },
  {
    from: '/zajistime-vam-statni-dotaci-na-rekonstrukci-domu/',
    to: '/',
    statusCode: 301
  },
  {
    from: '/zasilani-podkladu-a-dokumentu/',
    to: '/dolozeni-podkladu-online/',
    statusCode: 301
  },
  {
    from: '/blog/diky-dotacim-uz-nepotrebujete-zadne-penize-na-rekonstrukci-vaseho-rodinneho-domu/',
    to: '/',
    statusCode: 301
  },
  {
    from: '/nahravani-fotografii-k-uveru/',
    to: '/dolozeni-podkladu-online/dolozeni-fotek-pred-a-po-k-uveru-na-rekonstrukci-ci-vystavbu/',
    statusCode: 301
  },
  {
    from: '/soutez-burinka/',
    to: '/',
    statusCode: 301
  },
  {
    from: '/uver-na-rekonstrukci-online-podminky-pouzivani/',
    to: '/',
    statusCode: 301
  },
  {
    from: '/predprodukcni-test-online-uver/',
    to: '/',
    statusCode: 301
  },
  {
    from: '/pf19/',
    to: '/',
    statusCode: 301
  },
  {
    from: '/stastni-vyherci-souteze-se-stavebnim-sporenim/',
    to: '/',
    statusCode: 301
  },
  {
    from: '/casty_dotaz/',
    to: '/caste-dotazy/',
    statusCode: 301
  },
  {
    from: '/pojem/',
    to: '/slovnik-pojmu/',
    statusCode: 301
  },
  {
    from: '/uver-bez-zajisteni/',
    to: '/uvery-na-bydleni/uver-od-burinky/',
    statusCode: 301
  },
  {
    from: 'caste-dotazy/s-jakymi-poplatky-je-treba-pocitat/',
    to: '/caste-dotazy/kolik-me-zalozeni-stavebniho-sporeni-bude-stat',
    statusCode: 301
  },
  {
    from: '/caste-dotazy/proc-je-vyhodne-sporit-trvalym-prikazem/',
    to: '/caste-dotazy/jak-mam-postupovat-pri-platbe-z-bankovniho-uctu',
    statusCode: 301
  },
  {
    from: '/caste-dotazy/kdy-je-vyhodny-mimoradny-vklad/',
    to: '/slovnik-pojmu/#mimoradny-vklad',
    statusCode: 301
  },
  {
    from: '/caste-dotazy/jak-jsou-vklady-na-uctu-stavebniho-sporeni-uroceny/',
    to: '/slovnik-pojmu/#garantovany-urok',
    statusCode: 301
  },
  {
    from: '/caste-dotazy/co-je-to-cilova-castka/',
    to: '/slovnik-pojmu/cilova-castka/',
    statusCode: 301
  },
  {
    from: '/caste-dotazy/chci-vybrat-uspory-jak-na-to/',
    to: '/caste-dotazy/chci-ukoncit-smlouvu-jak-na-to',
    statusCode: 301
  },
  {
    from: '/caste-dotazy/co-je-to-usporena-castka/',
    to: '/slovnik-pojmu/#usporena-castka-zustatek-na-uctu-stavebniho-sporeni',
    statusCode: 301
  },
  {
    from: '/caste-dotazy/jake-jsou-podminky-statni-podpory/',
    to: '/slovnik-pojmu/#statni-podpora',
    statusCode: 301
  },
  {
    from: '/caste-dotazy/kdy-vam-burinka-zasle-vypis-z-uctu-stavebniho-sporeni/',
    to: '/media/2017/12/pruvodce-vypisem-z-uctu-ss.pdf',
    statusCode: 301
  },
  {
    from: '/caste-dotazy/jsou-moje-vklady-na-uctu-stavebniho-sporeni-pojisteny/',
    to: '/slovnik-pojmu/#pojisteni-vkladu',
    statusCode: 301
  },
  {
    from: '/caste-dotazy/co-je-to-cilova-castka',
    to: '/caste-dotazy/co-se-stane-kdyz-presporim-cilovou-castku',
    statusCode: 301
  },
  {
    from: '/caste-dotazy/co-je-to-vazaci-doba/',
    to: '/slovnik-pojmu/#vazaci-doba',
    statusCode: 301
  },
  {
    from: '/caste-dotazy/mohou-stavebni-sporeni-pro-nezletile-dite-sjednat-i-prarodice/',
    to: '/caste-dotazy/mohu-zalozit-stavebni-sporeni-a-sporit-svym-vnoucatum',
    statusCode: 301
  },
  {
    from: '/kategorie_dokumentu/formulare-a-sablony/',
    to: '/dokumenty-ke-stazeni/formulare/',
    statusCode: 301
  },
  {
    from: '/caste-dotazy/jak-je-mozne-ukoncit-stabebniho-sporeni/',
    to: '/caste-dotazy/chci-vybrat-uspory-jak-na-to/',
    statusCode: 301
  },
  {
    from: '/caste-dotazy/jak-sjednat-stavebniho-sporeni-pro-dite/',
    to: '/caste-dotazy/co-se-stane-kdyz-dite-dosahne-v-prubehu-sporeni-plnoletosti/',
    statusCode: 301
  },
  {
    from: '/caste-dotazy/jak-rychle-mi-bude-vyplacen-zustatek-ukonceneho-stavebniho-sporeni/',
    to: '/caste-dotazy/co-je-to-rychla-vyplata-a-kdy-ji-mohu-mit-zdarma',
    statusCode: 301
  },
  {
    from: '/vypisy-elektronicky/',
    to: '/poradna/nastavte-si-zasilani-vypisu-elektronicky/',
    statusCode: 301
  },
  {
    from: '/category/novinky/',
    to: '/aktuality/',
    statusCode: 301
  },
  {
    from: '/cenik/',
    to: '/dokumenty-ke-stazeni/ceniky-a-sdeleni/',
    statusCode: 301
  },
  {
    from: '/aktuality./',
    to: '/aktuality/',
    statusCode: 301
  },
  {
    from: '/fve/',
    to: '/uvery-na-bydleni/uver-od-burinky-pro-budoucnost/uver-na-fotovoltaiku/',
    statusCode: 301
  },
  {
    from: '/fotovoltaika/',
    to: '/uvery-na-bydleni/uver-od-burinky-pro-budoucnost/uver-na-fotovoltaiku/',
    statusCode: 301
  },
  {
    from: '/tc/',
    to: '/uvery-na-bydleni/uver-od-burinky-pro-budoucnost/uver-na-tepelne-cerpadlo/',
    statusCode: 301
  },
  {
    from: '/tepelne-cerpadlo/',
    to: '/uvery-na-bydleni/uver-od-burinky-pro-budoucnost/uver-na-tepelne-cerpadlo/',
    statusCode: 301
  },
  {
    from: '/pruvodce-rekonstrukci/',
    to: '/rekonstrukce/domov-pro-budoucnost/',
    statusCode: 301
  },
  {
    from: '/seznam-polozek/',
    to: '/media/2022/02/seznam-polozek-uveru-od-burinky-pro-budoucnost.pdf',
    statusCode: 301
  },
  {
    from: '/dolozeni-podkladu-online/dolozeni-fotek-pred-a-po-k-uveru-na-rekonstrukci-ci-vystavbu/',
    to: '/dolozeni-podkladu-online/dolozeni-fotek-k-uveru-na-rekonstrukci-ci-vystavbu/',
    statusCode: 301
  },
  {
    from: '/akce/',
    to: '/dokumenty-ke-stazeni/smluvni-podminky/podminky-akcnich-nabidek/',
    statusCode: 301
  },
  {
    from: '/predcasne-splaceni/',
    to: '/kalkulacka-pro-predcasne-splaceni/',
    statusCode: 301
  }
];
