import BurinkaVisualLibrary from '@burinka/visual-library';

export default defineNuxtPlugin((nuxtApp) => {
  // @ts-ignore
  nuxtApp.vueApp.use(BurinkaVisualLibrary, {
    floatingVue: {
      themes: {
        tooltip: {
          html: true
        }
      }
    }
  });
});
