import { createHttpLink, from } from '@apollo/client/core';
import { provideApolloClient } from '@vue/apollo-composable';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const { $apollo } = nuxtApp;

  const httpLink = createHttpLink({
    uri: `${config.public.wpEndpoint}/graphql`,
    credentials: 'same-origin'
  });

  // @ts-ignore
  $apollo.defaultClient.setLink(from([
    httpLink
  ]));

  // @ts-ignore
  provideApolloClient($apollo.defaultClient);
});
