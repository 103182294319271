import RedirectList from '@/data/redirects';

export default defineNuxtRouteMiddleware((to) => {
  const config = useRuntimeConfig();

  function redirectToOnboarding (url: string) {
    const regex = /\/formular-pro-online-sjednani/;

    if (!regex.test(url)) {
      return;
    }

    return navigateTo(config.public.onboardingRedirectUrl, {
      redirectCode: 301
    });
  }

  redirectToOnboarding(to.fullPath);

  const findRedirect = (RedirectList || []).find(redirectItem => redirectItem.from === to.path);

  if (findRedirect) {
    let { to: findRedirectUrl } = findRedirect;

    if (!findRedirectUrl.endsWith('/') && !findRedirectUrl.includes('#') && !findRedirectUrl.includes('.')) {
      findRedirectUrl = `${findRedirectUrl}/`;
    }

    return navigateTo(findRedirectUrl, {
      redirectCode: findRedirect.statusCode
    });
  }

  if (!to.path.endsWith('/') && !to.path.includes('.')) {
    return navigateTo(`${to.path}/`, {
      redirectCode: 301
    });
  }
});
