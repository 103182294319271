export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  if (!config.public.googleTagManagerId) {
    return;
  }

  const { googleTagManagerId } = config.public;

  useServerHead({
    script: [
      {
        src: `https://www.googletagmanager.com/gtm/js?id=${googleTagManagerId}`,
        async: true
      }
    ]
  });

  if (import.meta.server) {
    return;
  }

  window.dataLayer = window.dataLayer || [];

  function gtag (..._args: Record<string, unknown>[]): void {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(...arguments);
  }

  useHead({
    script: [

      {
        innerHTML: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${googleTagManagerId}');
        `,
        type: 'text/javascript'
      }
    ]
  });

  return {
    provide: {
      gtag
    }
  };
});
