import * as Sentry from '@sentry/vue';

// @ts-ignore
export default defineNuxtPlugin(({ vueApp }) => {
  const router = useRouter();
  const { public: { sentryDsn, env } } = useRuntimeConfig();

  if (!sentryDsn) {
    return {
      provide: {
        sentry: {
          captureException: (error: Error) => {
            console.error('Sentry is disabled: ');
            console.error(error);
          }
        }
      }
    };
  }

  Sentry.init({
    app: vueApp,
    dsn: sentryDsn,
    enabled: true,
    environment: env,
    integrations: [
      Sentry.browserTracingIntegration({ router })
    ],

    tracesSampleRate: 1.0 // Change in prod

    // tracePropagationTargets: ['localhost']
  });

  return {
    provide: {
      sentry: {
        setContext: Sentry.setContext,
        setUser: Sentry.setUser,
        setTag: Sentry.setTag,
        addBreadcrumb: Sentry.addBreadcrumb,
        captureMessage: Sentry.captureMessage,
        captureException: Sentry.captureException
      }
    }
  };
});
